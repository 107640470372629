import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'

export default function Component () {
  return <Layout>
    <section className="section section--gradient font-inter bg_white">
        <Header />
        
      <section className="">
        <div className="">
          <div className="pt-4">

              <Documentation /> 

          </div>
        </div>
      </section>
      </section>
      <Footer  overrideDarkTheme={true}/>
    </Layout>
}